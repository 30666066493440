/*
Licensed Materials - Property of IBM
694906H
(c) Copyright IBM Corp.  2020 All Rights Reserved

US Government Users Restricted Rights - Use, duplication or disclosure restricted
by GSA ADP Schedule Contract with IBM Corp.
*/

import { gql, useQuery } from '@apollo/client';

export const GET_CREATE_HIRE_QUERY = gql`
    query CreateClubHireBooking($createBooking: [CreateBooking]) {
        createClubHireBooking(createBooking: $createBooking) {
            dates {
                date
                bookings {
                    bookingID
                    bookingRef
                    location
                    taxable
                    teeTime
                }
            }
        }
    }
`;

export const useCreateHire = (createBooking) => {
    const { loading, error, data } = useQuery<genericReturn>(
        GET_CREATE_HIRE_QUERY,
        {
            notifyOnNetworkStatusChange: true,
            fetchPolicy: 'network-only',
            variables: { createBooking },
            skip: createBooking === null || createBooking === undefined
        });
    return { createHireLoading: loading, createHireerror: error, createHireResponse: data?.createClubHireBooking };
}

export type genericReturn = {
    createClubHireBooking: {
        dates: mainBookingResponse;
    };
};

export type mainBookingResponse = eachBooking[];

export type eachBooking = {
    date: string;
    bookings: bookingPerDate[];
};

export type bookingPerDate = {
    bookingID: number;
    bookingRef: string;
    location: string;
    taxable: string;
    teeTime: string;
};

// argument 
// {
//     "createBooking": [
//         {
//             "itineraryRef": null,
//             "bookingRef": null,
//             "email": null,
//             "date": null,
//             "courseName": null,
//             "contactName": null,
//             "locationId": null,
//             "notes": null,
//             "taxable": null,
//             "telephone": null,
//             "bookingItems": [
//                 {
//                     "itemID": null,
//                     "juniorQuantity": null,
//                     "quantity": null
//                 }
//             ],
//             "virtualItems": [
//                 {
//                     "itemID": null,
//                     "juniorQuantity": null,
//                     "quantity": null
//                 }
//             ]
//         }
//     ]
// }