/*
Licensed Materials - Property of IBM
694906H
(c) Copyright IBM Corp.  2020 All Rights Reserved

US Government Users Restricted Rights - Use, duplication or disclosure restricted
by GSA ADP Schedule Contract with IBM Corp.
*/

import {
  gql,
  useMutation
  // useLazyQuery
} from '@apollo/client';

export const PERSONAL_DATA = gql`
  mutation SaveProfileDetails($email: String!, $displayName: String!, $addressTypeCode: Int) {
    saveProfileDetails(email: $email, displayName: $displayName, addressTypeCode: $addressTypeCode)
  }
`;

export const usePersonalDataSubmit = () => {
  const [SaveProfileDetails] = useMutation(PERSONAL_DATA);

  return { SaveProfileDetails };
};

export const REGISTER = gql`
  mutation CustomerRegister($user: CusCustomerInput!, $address: CusAddressInput!) {
    customerRegister(user: $user, address: $address)
  }
`;

export const GET_CUSTOMER_ID = gql`
  query GetCustomerId($email: String) {
    customerId(email: $email) {
      id
    }
  }
`;
