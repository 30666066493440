/*
Licensed Materials - Property of IBM
694906H
(c) Copyright IBM Corp.  2020 All Rights Reserved

US Government Users Restricted Rights - Use, duplication or disclosure restricted
by GSA ADP Schedule Contract with IBM Corp.
*/

import styled, { css } from 'styled-components';
import { CommerceForm} from '@exo/frontend-components-forms';
import { media } from '@exo/frontend-common-style-utils';
import { ButtonUI } from '../../../../ButtonUI/ButtonUI';

export const StyledLoading = styled('h5')`
height:200px;
background-color: #f4f5f5;
padding-left: 39px;
margin-bottom: -65px;
color: var(--primary-blue-01-base-primary);
`;

export const FormWrapper = styled('div')`
  width: 100%;
  height: auto;
  padding-bottom: 40px;
  background-color: #f4f5f5;
  margin-bottom: -65px;
  // padding-top: 1px;
  // position:relative;
  // top: -140px;
  ${props => media.lessThan(props, 'medium').then(css`
     height: auto;
  `)}
  ${props => media.lessThan(props, 'small').then(css`
  padding-bottom: 25px;
  height:auto;
  margin-bottom: -300px;
  `)}
  
`;

export const Heading = styled('span')`
display:block;
padding-bottom:15px;
padding-left: 32px;
font: PlayfairDisplay;
font-size: 28px;
font-weight: normal;
font-stretch: normal;
font-style: normal;
line-height: 1.29;
letter-spacing: normal;
color: #87715a;
  ${props => media.lessThan(props, 'medium').then(css`
      width:360px;
      height:auto;
      padding-left: 30px;
  `)}
  ${props => media.lessThan(props, 'small').then(css`
    width:304px;
    height:36px;
    margin-top: 20px;
    padding-left: 16px;
    font-size: 25px;
    `)}
`;

export const Span = styled('span')`
display:block;
margin: 0px 0px 20px 0;
height: 24px;
padding-left: 32px;
font-family: Roboto;
font-size: 16px;
font-weight: normal;
font-stretch: normal;
font-style: normal;
line-height: 1.5;
letter-spacing: 0.25px;
color: var(--primary-blue-01-base-primary);
  ${props => media.lessThan(props, 'medium').then(css`
    width:90%;
    height:auto;
    font-size: 15px;
    `)}
  ${props => media.lessThan(props, 'small').then(css`
    width:294px;
    height:auto;
    padding-left: 16px;
    font-size: 15px;
    margin: 16px 0px;
    `)}
`;

export const MyDetailsForm = styled(CommerceForm)`
width: 100%;
padding-left: 32px;
margin-top: 1px;
height:auto;
  ${props => media.lessThan(props, 'small').then(css`
  padding-left: 16px;
  margin-top: 5px;
  height:auto;
    `)}
`;

export const FormContainer = styled('div')`
display:flex;
  ${props => media.lessThan(props, 'medium').then(css`
  display:block;
  `)}
  ${props => media.lessThan(props, 'small').then(css`
  display:block;
  height:auto;
  `)}
`;

export const TextInputWrapper = styled('div')`
  width: 352px;
  height: auto;
  font-family: Roboto;
  & input{
    height:48px;
  }

  ${props => media.lessThan(props, 'medium').then(css`
    width: auto;
    height:48px;
    font-size: 16px;
    margin-bottom:20px;
    padding-right:30px;
  `)}
  ${props => media.lessThan(props, 'small').then(css`
    width: 275px;
    height: 48px;
    padding-bottom:100px;
  `)}
`;

export const ButtonWrapper = styled('div')`

  ${props => media.lessThan(props, 'medium').then(css`
  padding-top:20px;
  margin-right: 30px;
  `)}
  ${props => media.lessThan(props, 'small').then(css`
  margin-bottom: 20px;
  `)}
`;

export const styledButton = styled(ButtonUI)`
height: 40px;
width: 128px;
margin-top: 24px;
margin-left: 32px;
font-family: Roboto;
  ${props => media.lessThan(props, 'medium').then(css`
  width: 100%;
  margin-left: 0px;
  `)}
  ${props => media.lessThan(props, 'small').then(css`
  width: 275px;
  margin-left: 0px;
  padding-top: 16px;
  margin-bottom:20px;
  `)}
`;


