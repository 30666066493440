/*
Licensed Materials - Property of IBM
694906H
(c) Copyright IBM Corp.  2020 All Rights Reserved

US Government Users Restricted Rights - Use, duplication or disclosure restricted
by GSA ADP Schedule Contract with IBM Corp.
*/

import React, { useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { CmsProviders } from '@exo/frontend-content-api';
import { getCodename } from '@exo/frontend-common-utils';
import { useKenticoCodename } from './hooks/useKenticoCodename';
import { useKenticoContentType } from './hooks/useKenticoContentType';
import { useKenticoTaxonomy } from './hooks/useKenticoTaxonomy';

const KenticoContainerContext = React.createContext<any>(undefined);

export const useContainerContext = () => useContext(KenticoContainerContext);

type Content = {
  loaded: boolean;
  notFound?: boolean;
  spec: Record<string, string>;
  content?: any;
};

type CmsContainerType = NonNullable<CmsProviders['CmsContainer']>;

const parseResponseByCodename = (obj: any) => {
  if(!obj.getByCodename){
    return {
      linkedItems: {}
    };
  }
  const res = JSON.parse(obj?.getByCodename?.response);
  // move the first element from items to item ;
  if (res?.items?.length > 0) {
    res.item = res?.items?.[0];
  } else if (
    // maybe we will remo
    Object.prototype.hasOwnProperty.call(res, 'linkedItems') &&
    Object.prototype.hasOwnProperty.call(res, 'item') &&
    !Object.prototype.hasOwnProperty.call(res.item, 'linkedItems')
  ) {
    res.item.linkedItems = { ...res.linkedItems };
  }
  return res;
};

const parseResponseByTaxonomy = (obj: any) => {
  const res = JSON.parse(obj?.getByTaxonomy?.response);
  // move the first element from items to item ;
  res.item = res?.items?.[0];
  return res;
};

const parseResponseByContentType = (obj: any) => {
  const res = JSON.parse(obj?.getByContentType?.response);
  // move the first element from items to item ;
  res.item = res?.items?.[0];
  return res;
};

export const KenticoCmsContainer: CmsContainerType = ({ name, spec, children }) => {

  const location = useLocation();
  const [content, setContent] = useState<Content>({ loaded: false, spec: {} });
  const [activeSpec, setActiveSpec] = useState<any>({
    ...spec,
    path: location.pathname,
    type: name
  });

  const isKSLEnabled = location.search.includes('ksl-enabled');

  let queryDepth = 1;
  let queryByCodename = false;
  let queryByContentType = false;
  let queryByTaxonomy = false;

  if (activeSpec.taxonomy) {
    queryByTaxonomy = true;
  }
  if (activeSpec.type !== null && activeSpec.type !== '') {
    queryByCodename = true;
  }
  if (
    Object.prototype.hasOwnProperty.call(activeSpec, 'content_type') &&
    activeSpec.content_type !== ''
  ) {
    queryByContentType = true;
  }
  if (!queryByCodename && !queryByContentType && !queryByTaxonomy) {
    queryByCodename = true;
    activeSpec.type = getCodename(location.pathname);
    // we don't do it by url anymore so we need to extract the URL
  }
  // const avlbCodeNames = ['club_hire_request_form', 'advanced_reservations', 'email_preferences', 'email_preferences_submitted',
  //                       'the_home_of_golf', 'shop', 'webcam', 'links_ticketholders', 'privacy-policy', 'accessibility', 'governance',
  //                       'partners_allianz','partners_callaway', 'partners_nbc', 'partners_rolex', 'partners_toro','partners_tsi_holdings','partners_toptracer',
  //                       'home-of-golf/partners/allianz', 'home-of-golf/partners/callaway', 'home-of-golf/partners/nbc',
  //                       'home-of-golf/partners/rolex', 'home-of-golf/partners/toro', 'home-of-golf/partners/tsi-holdings', 'home-of-golf/partners/toptracer'];
  // const checkCodeName=(addOnUrl)=>{
  //   return avlbCodeNames.indexOf(addOnUrl) >= 0 ? addOnUrl : 'homepage';
  // };

  const { data: contentData } = useKenticoCodename(activeSpec.type, (activeSpec.type === 'homepage' ? 2 : queryDepth), !queryByCodename);
  const { data: taxonomyData } = useKenticoTaxonomy(
    activeSpec.taxonomy,
    queryDepth,
    !queryByTaxonomy
  );
  const { data: contentTypeData, onLoadMore } = useKenticoContentType(
    activeSpec.content_type,
    activeSpec.filter,
    queryDepth,
    !queryByContentType,
    Number(activeSpec.limit),
    activeSpec.skip,
    activeSpec.sortBy,
    activeSpec.excludeCodename
  );

  useEffect(() => {
    setActiveSpec({ ...activeSpec, ...spec });
  }, [spec]);

  useEffect(() => {
    if (contentData && contentData !== undefined) {
      const parsedData: any = parseResponseByCodename(contentData);
      parsedData.loadMoreCallback = onLoadMore;
      document.dispatchEvent(new CustomEvent('showLoading', { detail: false }));
      setContent({
        spec: activeSpec,
        loaded: true,
        content: parsedData
      });
    }
  }, [contentData]);

  useEffect(() => {
    if (taxonomyData && taxonomyData !== undefined) {
      const parsedData: any = parseResponseByTaxonomy(taxonomyData);
      document.dispatchEvent(new CustomEvent('showLoading', { detail: false }));
      setContent({
        spec: activeSpec,
        loaded: true,
        content: parsedData
      });
    }
  }, [taxonomyData]);

  useEffect(() => {
    if (contentTypeData && contentTypeData !== undefined) {
      const parsedData: any = parseResponseByContentType(contentTypeData);
      parsedData.loadMoreCallback = onLoadMore;
      document.dispatchEvent(new CustomEvent('showLoading', { detail: false }));
      setContent({
        spec: activeSpec,
        loaded: true,
        content: parsedData
      });
    }
  }, [contentTypeData]);

  if (!content || !content.loaded) {
   // console.log('Content not loaded');

    //return <></>;
  }
  else{
    //console.log('content',content)
  }

  if (content.notFound) {
    document.dispatchEvent(new CustomEvent('showLoading', { detail: false }));
    return <div>Error: Cannot find component</div>;
  }

  let kslProps = {};
  if (isKSLEnabled) {
    const systemProps = content?.content?.item?.system;
    kslProps = {
      'data-kontent-item-id': systemProps?.id,
      'data-kontent-element-codename': systemProps?.codename
    };
  }

  return (
    <KenticoContainerContext.Provider value={content.content}>
      <div {...kslProps}>{children}</div>
    </KenticoContainerContext.Provider>
  );
};
