/* 
Licensed Materials - Property of IBM
694906H
(c) Copyright IBM Corp.  2020 All Rights Reserved

US Government Users Restricted Rights - Use, duplication or disclosure restricted
by GSA ADP Schedule Contract with IBM Corp.
*/

import { useMutation, gql } from '@apollo/client';

// TODO: We should change this mutation
/* const SET_PAYMENT = gql`
  mutation usePayment($financeId: ID, $tradeInId: ID) {
    payInitiate(financeId: $financeId, tradeInId: $tradeInId)
  }
`; */

const PROCESS_AUTH = gql`
  mutation usePayment($authResponse: Object, $cartId: String, $bookingDetails: Object ) {
    processAuthorisation(authResponse: $authResponse, cartId: $cartId , bookingDetails: $bookingDetails )
  }
`;

//todo : create another init payment mutation with reference id as per backend
const INIT_PAYMENT = gql`
  mutation usePayment($cartId: String) {
    initPayment(cartId: $cartId)
  }
`;

const INIT_ADVANCE_PAYMENT = gql`
  mutation usePayment($refNum: String, $amount: String, $currency: String) {
    initAdvancePayment(refNum: $refNum, amount: $amount, currency: $currency)
  }
`;

// Mutation for reservation Payment
const PROCESS_ADVANCE_AUTH = gql`
  mutation usePayment($authResponse: Object,$refNum: String, $amount: String) {
    processAdvanceAuthorisation(authResponse: $authResponse, refNum:$refNum, amount: $amount )
  }
`;

export const usePayment = () => {
  const [initiatePayment] = useMutation(INIT_PAYMENT, {
    onError: error => {
      if (process.env.NODE_ENV === 'development' || process.env.JEST_WORKER_ID !== undefined) {
        // eslint-disable-next-line no-console
        console.error(`Error in ${__filename}: ${JSON.stringify(error, undefined, '  ')}`);
      }
      throw error;
    }
  });
  const [finalPayment] = useMutation(PROCESS_AUTH, {
    onError: error => {
      if (process.env.NODE_ENV === 'development' || process.env.JEST_WORKER_ID !== undefined) {
        // eslint-disable-next-line no-console
        console.error(`Error in ${__filename}: ${JSON.stringify(error, undefined, '  ')}`);
      }
      throw error;
    }
  });
  const [initPayment] = useMutation(INIT_PAYMENT, {
    onError: error => {
      if (process.env.NODE_ENV === 'development' || process.env.JEST_WORKER_ID !== undefined) {
        // eslint-disable-next-line no-console
        console.error(`Error in ${__filename}: ${JSON.stringify(error, undefined, '  ')}`);
      }
      throw error;
    }
  });
  const [initAdvancePayment] = useMutation(INIT_ADVANCE_PAYMENT, {
    onError: error => {
      if (process.env.NODE_ENV === 'development' || process.env.JEST_WORKER_ID !== undefined) {
        // eslint-disable-next-line no-console
        console.error(`Error in ${__filename}: ${JSON.stringify(error, undefined, '  ')}`);
      }
      throw error;
    }
  });
  const [finalAdvancePayment] = useMutation(PROCESS_ADVANCE_AUTH, {
    onError: error => {
      if (process.env.NODE_ENV === 'development' || process.env.JEST_WORKER_ID !== undefined) {
        // eslint-disable-next-line no-console
        console.error(`Error in ${__filename}: ${JSON.stringify(error, undefined, '  ')}`);
      }
      throw error;
    }
  });


  return {
    initiatePayment: async ({
      financeId,
      tradeInId
    }: {
      financeId?: string;
      tradeInId?: string;
    }) => {
      return initiatePayment({
        variables: { financeId, tradeInId }
      });
    },

    initPayment: async ({
      cartId
    }: {
      cartId?: string;
    }) => {
      return initPayment({
        variables: { cartId }
      });
    },

    initAdvancePayment: async ({
      refNum, amount, currency
    }: {
      refNum: string;
      amount: string;
      currency: string;
    }) => {
      return initAdvancePayment({
        variables: { refNum, amount, currency }
      });
    },

    ProcessAuth: async ({
      auth, cartId, bookingDetails
    }: {
      auth?: any;
      cartId?: string;
      bookingDetails: {
        idsPiped: string | undefined
      };
    }) => {
      return finalPayment({
        variables: { authResponse: auth, cartId, bookingDetails }
      });
    },

    ProcessAdvanceAuth: async ({
      auth, refNum, amount
    }: {
      auth?: any;
      refNum: string;
      amount: string;
    }) => {
      return finalAdvancePayment({
        variables: { authResponse: auth, refNum, amount }
      });
    }
  };
};
