/* eslint-disable react/jsx-one-expression-per-line */
/*
Licensed Materials - Property of IBM
694906H
(c) Copyright IBM Corp.  2020 All Rights Reserved

US Government Users Restricted Rights - Use, duplication or disclosure restricted
by GSA ADP Schedule Contract with IBM Corp.
*/

import { gql } from '@apollo/client';
import {
  renderDefaultError,
  renderDefaultLoading,
  SmartComponentProps
} from '@exo/frontend-common-utils';
import { useOrderHistory } from '../../hooks/useOrderHistory';

export const OrderHistoryContainer = ({
  render,
  renderLoading = renderDefaultLoading,
  renderError = renderDefaultError
}: Props) => {
  const { data, loading, error } = useOrderHistory<GQLResponse>({}, OrderHistoryContainer.fragment);
  const allZones=data && JSON.parse(data?.me?.zones)
  let zoneCountryMapper:any={}
  allZones?.results && allZones?.results?.forEach((eachZone)=>{
    zoneCountryMapper[eachZone?.name]=eachZone?.locations?.map((loc)=>loc?.country)
  })
  if (loading) return renderLoading();
  if (error) return renderError(error);

  return render({ orders: data?.me?.orders?.edges?.map(o => o.node) ?? [], zoneCountryMapper});
};

type Props = SmartComponentProps<{
  render: (args: OrderHistoryContainerRenderProps) => JSX.Element|any;
}>;

export type OrderHistoryContainerRenderProps = {
  orders: Order[];
  zoneCountryMapper: any;
};

OrderHistoryContainer.fragment = gql`
  fragment OrderHistoryContainer on CusMe {
    id
    zones
    orders {
      edges {
        node {
          id
          grandTotal {
            value
            currency
          }
          placedDate
          updateDate
          status
          statusCode
          shippingAddress {
            firstName
            lastName
            streetName
            additionalStreetInfo
            postalCode
            city
            region
            country
            phone
            email
            key
            trackingLink
          }
          lineItems {
            id
            linePrice {
              value
              currency
            }
            partnumber
            quantity
            item {
              name
              thumbnail
            }
          }
          customLineItems {
            id
            slug
            linePrice {
              value
              currency
            }
            partnumber
            quantity
            item {
              id
            }
          }
        }
      }
    }
  }
`;

type MonetaryAmount = {
  value: string | number;
  currency: string;
};

type GQLResponse = {
  zones: string;
  orders: {
    edges: {
      node: Order;
    }[];
  };
};

export type Order = {
  id: string;
  grandTotal: MonetaryAmount;
  placedDate: string;
  updateDate: string;
  status: string;
  statusCode: string;
  shippingAddress: {
    firstName: string;
    lastName: string;
    streetName: string;
    additionalStreetInfo: string;
    postalCode: string;
    city: string;
    region: string;
    country: string;
    phone: string;
    email: string;
    key: string;
    trackingLink: string;
  };
  lineItems: {
    id: string;
    linePrice: MonetaryAmount;
    partnumber: string;
    quantity: number;
    item: {
      name: string;
      thumbnail?: string;
      id?: string;
    };
    slug?: string;
  }[];
  customLineItems: {
    id: string;
    linePrice: MonetaryAmount;
    partnumber: string;
    quantity: number;
    item: {
      thumbnail?: string;
      id: string;
      name?: string;
    };
    slug?: string;
  }[];
};
