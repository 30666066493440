/*
Licensed Materials - Property of IBM
694906H
(c) Copyright IBM Corp.  2020 All Rights Reserved

US Government Users Restricted Rights - Use, duplication or disclosure restricted
by GSA ADP Schedule Contract with IBM Corp.
*/

import { gql, useQuery } from '@apollo/client';

export const GET_CONTENT_BY_TAXONOMY = gql`
  query GetByTaxonomy($codename: String!, $depth: Int) {
    getByTaxonomy(codename: $codename, depth: $depth) {
      response
    }
  }
`;

export type GenericReturn = {
    getByTaxonomy: {
    response: string;
  };
};

export const useKenticoTaxonomy = (codename: string, depth?: number, skipCall: boolean = false) => {
  const { loading, error, data } = useQuery<GenericReturn>(GET_CONTENT_BY_TAXONOMY, {
    variables: { codename, depth },
    skip: skipCall,
    fetchPolicy: 'cache-first'
  });
  return { loading, error, data };
};
