/*
Licensed Materials - Property of IBM
694906H
(c) Copyright IBM Corp.  2020 All Rights Reserved

US Government Users Restricted Rights - Use, duplication or disclosure restricted
by GSA ADP Schedule Contract with IBM Corp.
*/

import { gql, useQuery } from '@apollo/client';

const addNesting = (d: number) => {
  if (d == 0) {
    return '';
  }

  return `
    children {
      __typename
      ... on NavContentNode {
        ...ContentNode
        ${addNesting(d - 1)}
      }
    }
  `;
};

export const makeQuery = (d: number) => gql`
  query GetMenuItems($id: String!) {
    navRoot(id: $id) {
      ${addNesting(d)}
    }
  }

  fragment ContentNode on NavContentNode {
    __typename
    title
    thumbnail
    description
    visible
    type
    link {
      __typename
      label
      ... on NavCategoryLink {
        category {
          id
          identifier
          slug
          visible
          childCategories {
            id
            name
            slug
            visible
            orderHint
            parentCategory {
              id
              name
              slug
              visible
            }
            childCategories {
              id
              name
              slug
              visible
              parentCategory {
                id
                name
                slug
                visible
              }
            }
          }
        }
      }
      ... on NavUrlLink {
        url
      }
    }
  }
`;

export const getCodeNameData = gql`
  query GetByCodename($codename: String!, $depth: Int) {
    getByCodename(codename: $codename, depth: $depth) {
      response
    }
  }
`;

export type NavContentNode = {
  __typename: string;
  title: string;
  thumbnail?: string;
  description?: string;
  visible?: boolean;
  type?: string;
  link: {
    __typename: string;
    label: string;
    url: string;
    category?: {
      id: string;
      identifier: string;
      slug: string;
      visible?: boolean;
      childCategories?: [
        {
          id: string;
          visible?: boolean;
          name: string;
          slug: string;
          orderHint?:string;
          parentCategory?: {
            id: string;
            visible?: boolean;
            name: string;
            slug: string;
          };
        }
      ];
    };
  };
  children?: NavContentNode[];
};

export type GenericReturn = {
  getByCodename: {
    response: string;
  };
};

export const useNavigation = (id?: string, depth = 3) => {
  const { loading, error, data } = useQuery<{ navRoot: { children: NavContentNode[] } }>(
    makeQuery(depth),
    {
      variables: { id }
    }
  );

  return { loading, error, data };
};

export const getCMSData = (codename: string, depth?: number) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { loading, error, data } = useQuery<GenericReturn>(getCodeNameData, {
    variables: { codename, depth },
    fetchPolicy: 'cache-first'
  });

  return { loading, error, data };
};
