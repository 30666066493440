/*
Licensed Materials - Property of IBM
694906H
(c) Copyright IBM Corp.  2020 All Rights Reserved

US Government Users Restricted Rights - Use, duplication or disclosure restricted
by GSA ADP Schedule Contract with IBM Corp.
*/

import { gql, useQuery } from '@apollo/client';
import { useState } from 'react';

export const GET_CONTENT_BY_CONTENT_TYPE = gql`
  query GetByContentType(
    $contentType: String!
    $filter: String
    $excludeCodename: String
    $depth: Int
    $limit: Int
    $skip: Int
    $sortBy: String
  ) {
    getByContentType(
      contentType: $contentType
      filter: $filter
      excludeCodename: $excludeCodename
      depth: $depth
      limit: $limit
      skip: $skip
      sortBy: $sortBy
    ) {
      response
    }
  }
`;

export type GenericReturn = {
  getByContentType: {
    response: string;
  };
};

export const useKenticoContentType = (
  contentType: string,
  filter: string,
  depth?: number,
  skipCall: boolean = false,
  limit?: number,
  skip?: number,
  sortBy?: string,
  excludeCodename? : string
) => {
  const { loading, error, data, fetchMore } = useQuery<GenericReturn>(GET_CONTENT_BY_CONTENT_TYPE, {
    variables: { contentType, filter, depth, limit, skip, sortBy, excludeCodename },
    skip: skipCall,
    notifyOnNetworkStatusChange: true,
    fetchPolicy: 'cache-first'
  });
  const [skipCount, setSkipCount] = useState(limit || 4);

  const onLoadMore = () => {
    setSkipCount((prev) => prev + skipCount);
    fetchMore({
      variables: {
        contentType,
        filter,
        limit,
        skip: skip || skipCount,
        sortBy
      },
      updateQuery: (prev, { fetchMoreResult }) => {
        const prevToJSON = JSON.parse(prev.getByContentType.response);

        if (!fetchMoreResult) return prev;

        const nextToJson = JSON.parse(fetchMoreResult.getByContentType.response);
        nextToJson.items = [...prevToJSON.items, ...nextToJson.items];

        return {
          getByContentType: {
            response: JSON.stringify({ ...prevToJSON, ...nextToJson })
          }
        };
      }
    });
  };
  return { loading, error, data, onLoadMore };
};
