/*
Licensed Materials - Property of IBM
694906H
(c) Copyright IBM Corp.  2020 All Rights Reserved

US Government Users Restricted Rights - Use, duplication or disclosure restricted
by GSA ADP Schedule Contract with IBM Corp.
*/

import { gql, useApolloClient, useMutation } from '@apollo/client';
import { useSessionContext } from '@exo/frontend-common-session-context';
import { useProfileModification } from '@exo/frontend-features-account-profile-logic';
import { Address, cleanAddress } from '../model/address';

export const GQL_SET_SHIPPING_INFO = gql`
  mutation CartSetShippingInfo(
    $address: CusAddressInput
    $addressId: ID
    $cartId: ID!
    $shipModeId: ID
    $storeId: ID
    $collectionDate: String
  ) {
    cartSetShippingInfo(
      address: $address
      addressId: $addressId
      cartId: $cartId
      shipModeId: $shipModeId
      storeId: $storeId
      collectionDate: $collectionDate
      lineItemIds: []
    )
  }
`;

const GQL_ADD_PAYMENT_METHOD = gql`
  mutation CartUpdatePaymentMethod(
    $addressId: ID
    $cartId: ID!
    $address: CusAddressInput
    $paymentMethodId: ID!
    $attributes: [CrtPaymentAttributeInput]
  ) {
    cartAddPaymentInstruction(
      cartId: $cartId
      addressId: $addressId
      address: $address
      paymentMethodId: $paymentMethodId
      attributes: $attributes
    )
  }
`;

const GQL_DELETE_PAYMENT_METHODS = gql`
  mutation CartClearPaymentMethods($cartId: ID!) {
    cartDeletePaymentInstructions(cartId: $cartId)
  }
`;

const GQL_CHECKOUT = gql`
  mutation Checkout($cartId: ID!) {
    checkout(cartId: $cartId)
  }
`;

const GQL_GET_CART = gql`
  query CartById($id: ID) {
    me {
      id
      carts(cartId: $id) {
        id
        grandTotal {
          ...Currency
        }
        totalProductPrice {
          ...Currency
        }
        totalShippingCharge {
          ...Currency
        }
        totalAdjustment {
          ...Currency
        }
        totalShippingTax {
          ...Currency
        }
        totalSalesTax {
          ...Currency
        }
        adjustments {
          title
          amount {
            ...Currency
          }
        }
        shippingInfo {
          shipMode {
            id
            identifier
            shippingRate {
              currency
              value
            }
          }
          pickupInStore {
            id
            name
          }
          shippingAddress {
            id
            country
            zip
          }
        }
        lineItems {
          id
          quantity
          partnumber
          linePrice {
            ...Currency
          }
          unitPrice {
            ...Currency
          }
          sizeAttr {
            name
            value
          }
          product {
            id
            type
            thumbnail
            name
            partnumber
            price {
              list {
                ...Currency
              }
              offer {
                ...Currency
              }
            }
          }
        }
        customLineItems {
          id
          name {
            en
          }
          money {
            currencyCode
            centAmount
          }
          totalPrice {
            currencyCode
            centAmount
          }
          slug
          quantity
        }
      }
    }
  }

  fragment Currency on CmmMoney {
    value
    currency
  }
`;

const GQL_CREATE_CART = gql`
  mutation CreateCart($oldCartId: ID, $currency: String) {
    cartCreate(oldCartId: $oldCartId, currency: $currency) {
      id
    }
  }
`;

const GQL_ACKNOWLEDGE_PAYMENT = gql`
mutation CheckoutAdvancePayment($transactionId: String!, $firstName: String!, $email: String!,$amount: String!,$refNum: String!) {
  checkoutAdvancePayment(transactionId: $transactionId, firstName: $firstName, email: $email, amount:$amount, refNum:$refNum)
}
`;

const GET_BLUERUNNER_INFO = gql`
query bluerunnerCardEnquiryByID($cardId: String!) {
  bluerunnerCardEnquiryByID(cardId: $cardId){
    balance
  }
}
`;

// TODO: There's some opportunity to reduce duplication as some of this is handled in useCartModification
export const useCheckout = () => {
  const apolloClient = useApolloClient();
  const session = useSessionContext();
  const { addAddress, updateAddress } = useProfileModification();

  const [gqlAddPaymentMethod] = useMutation(GQL_ADD_PAYMENT_METHOD);
  const [gqlDeletePaymentMethods] = useMutation(GQL_DELETE_PAYMENT_METHODS);
  const [gqlCheckout] = useMutation(GQL_CHECKOUT);
  const [gqlSetShippingInfo] = useMutation(GQL_SET_SHIPPING_INFO);
  const [gqlCreateCart] = useMutation(GQL_CREATE_CART);
  const [gqlAcknowledgePayment] = useMutation(GQL_ACKNOWLEDGE_PAYMENT);

  const cartId = session?.cartId;


  return {
    setShippingMode: async (
      shipModeId: string,
      addressId: string,
      storeId: string,
      collectionDate: string
    ) => {
      return gqlSetShippingInfo({
        variables: { storeId, addressId, shipModeId, cartId: session?.cartId, collectionDate }
      });
    },

    updateShippingAddress: async (address: Address, shipModeId: string, collectionDate: string) => {
      const addressClean = cleanAddress(address);
      let addressId = address.id;
      if(session?.pickNCollect !== 'ClickNCollect'){
   
        if (session.type !== 'GUEST' ) {
          if (!addressId) {
            const res = await addAddress({
              ...addressClean,
              name: `${addressClean.firstName}${addressClean.lastName}`
            });
            addressId = res.data.customerAddAddress;
          } else {
            const res = await updateAddress(address.id!, addressClean);
            addressId = res.data.customerUpdateAddress;
          }
        }
      }
      

      await gqlSetShippingInfo({
        variables: {
          cartId,
          ...(addressId && { addressId }),
          ...(!addressId && {
            address: { ...addressClean, name: `${addressClean.firstName}${addressClean.lastName}` }
          }),
          ...(session?.checkout?.storeId ? { storeId: session?.checkout?.storeId } : {}),
          shipModeId,
          collectionDate
        }
      });

      return addressId;
    },

    createBillingAddress: async (address: Address) => {
      await gqlDeletePaymentMethods({ variables: { cartId } });
      const addressClean = cleanAddress(address);

      // TODO: We should push some of this into the profileModification hook - including
      // cleaning of the address
      let id: string;

      if (address.id) {
        const res = await updateAddress(address.id, addressClean);
        id = res.data.customerUpdateAddress;
      } else {
        const res = await addAddress({
          ...addressClean,
          name: `${addressClean.firstName}${addressClean.lastName}`
        });
        id = res.data.customerAddAddress;
      }

      return id;
    },

    setPaymentMethod: async (
      addressId: string | undefined,
      address: Address,
      paymentMethodId: string,
      attributes?: { name: string; value: string }[]
    ) => {
      await gqlDeletePaymentMethods({ variables: { cartId } });
      return gqlAddPaymentMethod({
        variables: {
          cartId,
          addressId,
          address: addressId ? undefined : cleanAddress(address),
          paymentMethodId,
          attributes: attributes ?? []
        }
      });
    },

    getBlueRunnerCardInfo:  async (cardId) => {
      return apolloClient.query({
        query: GET_BLUERUNNER_INFO,
        fetchPolicy: 'network-only',
        variables: { cardId }
      });
    },

    getCartForSummary: async (id = '-1') => {
      return apolloClient.query({
        query: GQL_GET_CART,
        fetchPolicy: 'network-only',
        variables: { id }
      });
    },

    placeOrder: async (id = '-1') => {
      const res = await gqlCheckout({ variables: { cartId: id } });
      return JSON.parse(res?.data?.checkout);
    },

    confirmAdvancePayment: async (transactionId,firstName,email,amount,refNum ) => {
      gqlAcknowledgePayment({ variables: { transactionId,firstName,email,amount,refNum} });
    },

    createCart: async ({ oldCartId, currency }: { oldCartId?: string; currency?: string }) => {
      const res = await gqlCreateCart({
        variables: { oldCartId, currency }
      });
      return res?.data?.cartCreate?.id;
    }
  };
};
