/*
Licensed Materials - Property of IBM
694906H
(c) Copyright IBM Corp.  2020 All Rights Reserved

US Government Users Restricted Rights - Use, duplication or disclosure restricted
by GSA ADP Schedule Contract with IBM Corp.
*/

import { useLazyQuery, useMutation } from '@apollo/client';
import { handleApolloError } from '@exo/frontend-common-apollo';
import gql from 'graphql-tag';

const SET_GOLFER_DETAILS = gql`
  mutation SetGolferData(
    $email: String!
    $displayName: String!
    $firstname: String
    $gender: String
    $dob: String
    $city: String
    $country: String
    $address: String
    $lastname: String
    $province: String
    $postcode: String
    $phone: String
  ) {
    saveProfileDetails(
      email: $email
      displayName: $displayName
      firstname: $firstname
      gender: $gender
      dob: $dob
      city: $city
      country: $country
      address: $address
      lastname: $lastname
      province: $postcode
      postcode: $province
      phone: $phone
    )
  }
`;

const GET_GOLFER_DETAILS = gql`
  query GetGolferDetails($email: String!) {
    getGolferDetails(email: $email) {
      email
      displayName
      lastname
      address
      city
      purchaseHistory {
        purchaseDate
        store
        paymentGatewayAmount
      }
      golferId
      firstname
      country
      dob
      gender
      province
      postcode
      phone
    }
  }
`;

export const useGolferData = <T>(): Result<T> => {
  const [getGolferInfo, { data, loading: getLoading, error: getError }] =
    useLazyQuery(GET_GOLFER_DETAILS, {
      fetchPolicy: 'network-only' // Doesn't check cache before making a network request
    });
  const [setGolferInfo, { data: setGolferInfoData, loading: setLoading, error: setError }] =
    useMutation(SET_GOLFER_DETAILS);

  handleApolloError(__filename, getError);

  return {
    getGolferData: async (email: string) => {
      return getGolferInfo({ variables: { email } });
    },
    setGolferData: async (golferData: GolferData) => {
      const golferDataMap = {
        address: golferData.address1,
        firstname: golferData.firstName,
        lastname: golferData.lastName,
        postcode: golferData.zip,
        email: golferData.email,
        displayName: golferData.displayName,
        firstName: golferData.firstName,
        lastName: golferData.lastName,
        gender: golferData.gender,
        dob: golferData.dob,
        city: golferData.city,
        country: golferData.country,
        phone: golferData.phone,
        province: golferData.province
      };
      
      return setGolferInfo({ variables: { ...golferDataMap } });
    },
    data,
    getError,
    getLoading,
    setGolferInfoData,
    setLoading,
    setError
  };
};

type GolferData = {
  email: string;
  displayName: string;
  firstName?: string;
  lastName?: string;
  gender?: string;
  dob?: string;
  city?: string;
  country?: string;
  address1?: string;
  zip?: string;
  phone?: string;
  province?: string;
};

type Result<T> = {
  getGolferData: (email: string) => Promise<any>;
  setGolferData: (golferData: GolferData) => Promise<any>;
  data: T;
  setGolferInfoData: T;
  getError: any;
  setError: any;
  getLoading: boolean;
  setLoading: boolean;
};
