/*
Licensed Materials - Property of IBM
694906H
(c) Copyright IBM Corp.  2020 All Rights Reserved

US Government Users Restricted Rights - Use, duplication or disclosure restricted
by GSA ADP Schedule Contract with IBM Corp.
*/

import { gql, useQuery } from '@apollo/client';
export const GET_PRODUCT_DATA_QUERY = gql`
query ProductByKey($key: String) {
    productByKey(key: $key) {
      id
      name
      children {
        id
        name
        partnumber
        attributes {
          id
          name
          value {
            id
            value
          }
        }
      }
    }
  }
`;

export const useKey = (key) => {
  const { loading: loadingProduct, error, data: productData, refetch: refetchProduct } = useQuery(
    GET_PRODUCT_DATA_QUERY,
    {
      notifyOnNetworkStatusChange: true,
      fetchPolicy: 'cache-first',
      variables: { key },
      skip: key === null || key === undefined || key === ""
    });
  return { loading: loadingProduct, error, data: productData, refetch: refetchProduct };
}